@use 'layout/variables';

.content {
  background: variables.$primary;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  color: variables.$white;
  height: 80px;
  font-weight: 500;
  font-size: 14px;
}

.value {
  font-weight: 700;
  font-size: 16px;
}
