.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .links {
    a {
      margin-right: 15px;
    }

    a:hover {
      text-decoration: none;
    }
  }
}