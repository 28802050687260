.note {
  background-color: #d3d2ff;
  border-radius: 10px;
  padding: 14px 28px;
  width: fit-content;
  line-height: 16px;

  p {
    width: fit-content;
    margin: 0;
  }
}

.ctaAdd {
  margin-top: 16px;
}
