.tableWrapper {
  max-height: calc(100vh - 210px);
  overflow: auto;
}

.borderRight {
  border-right: 1px solid #3e3c3c;
}

.th {
  border: 0;
  outline: '1px solid #918f8f';
}

.employeesTooltip {
  font-size: 10px;

  dl {
    margin: 0;
    padding: 0;
  }
  
  dt {
    margin-left: 12px;
  }

  .employeesStatus {
    font-weight: 700;
    display: inline-block;
    margin-right: 6px;
    text-transform: uppercase;
  }
  .employeeList {
    list-style: circle;
  }
}
