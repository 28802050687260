table[class^="MuiTable"],
table[class*="MuiTable"] {
  thead {
    tr {
      th {
        font-weight: 600;
      }
    }
  }

  tbody {
    tr {
      th {
        a {
          color: #154dae;
          text-decoration: underline;
        }
        a:hover {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
}
